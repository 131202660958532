import React, { useContext, useEffect, useRef } from "react";
import "./SideMenu.scss";
import brand from '../../../img/svg/brand.svg'
import fb from '../../../img/svg/facebook.svg'
import insta from '../../../img/svg/instagram.svg'
import linkedin from '../../../img/svg/linkedin.svg'
import twitter from '../../../img/svg/twitter.svg'
import closeBtn from '../../../img/svg/closeBtn.svg'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Endpoints } from '../../../routes';
import { AppContext } from '../../../context/AppContext';
import { DIRECTOR, PHOTOGRAPHER } from '../../../value';
import { isDesktop } from 'react-device-detect';
export interface ISideMenuProps {
  active: string;
  closeNav: Function;
  isOpen: boolean;
}
function SideMenu(props: ISideMenuProps) {
  const {
    setDirectorsClicked,
    directorsClicked,
    more,
    setMore,
    activeVideoKey,
    setActiveVideoKey,
  } = useContext(AppContext);

  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (props.isOpen) {
      isDesktop
        ? (ref.current.style.width = '30vw')
        : (ref.current.style.width = '100vw');
    } else {
      ref.current.style.width = '0';
    }
  }, [props.isOpen]);


  return (
    <div id="mySidenav" className="sidenav" ref={ref}>
      <div className="sidenav-header-wrapper">
        <div className="header">
          <a className="brand">{!isDesktop && <img src={brand} />}</a>

          <img
            src={closeBtn}
            className="closebtn"
            onClick={() => {
              props.closeNav();
            }}
          />
        </div>
      </div>
      <div className="nav-list">
        <div className="nav-list-item">
          {pathname !== '/' ? (
            <Link
              // className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              to={Endpoints.HOME}
            >
              Home
            </Link>
          ) : (
            <Link
              // className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              to={Endpoints.HOME}
              onClick={() => {
                navigate('/');
                setMore(false);
                setActiveVideoKey(0);
                props.closeNav();
              }}
              style={{
                color: more && activeVideoKey == 6 ? 'white' : '#9D9D9C',
                pointerEvents: more && activeVideoKey == 6 ? 'auto' : 'none',
              }}
            >
              Home
            </Link>
          )}
        </div>
        <div className="nav-list-item">
          {/* <NavLink
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            to={Endpoints.HOME}
            onClick={() => setActiveVideoKey(6)}
          >
            Directors
          </NavLink> */}
          <Link
            to="/"
            onClick={() => {
              navigate('/');
              setDirectorsClicked(true);
              // onLinkClick();
              props.closeNav();
            }}
            style={{
              color: !more && activeVideoKey != 6 ? 'white' : '#9D9D9C',
              pointerEvents: !more && activeVideoKey != 6 ? 'auto' : 'none',
            }}
          >
            Directors
          </Link>
          {/* <Link
            to="/"
            onClick={() => {
              // setDirectorsClicked(true);
              onLinkClick();
              props.closeNav();
            }}
            style={{ listStyle: 'none' }}
          >
            <li>Directors</li>
          </Link> */}
        </div>
        {/* <div className="nav-list-item">
          <NavLink
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            to={Endpoints.EXPLORE_VIDEOS}
          >
            Projects
          </NavLink>
        </div> */}
        <div className="nav-list-item">
          <NavLink
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            to={Endpoints.CONTACT}
            onClick={() => {
              setMore(false);
              setActiveVideoKey(0);
            }}
          >
            About & Contact
          </NavLink>
          <div className="nav-list-item">
            <NavLink
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              to={Endpoints.IMPRINT}
              onClick={() => {
                setMore(false);
                setActiveVideoKey(0);
              }}
            >
              Imprint
            </NavLink>
          </div>
        </div>
        <div className="nav-list-item">
          <div className="social-media">
            <div id="side-menu-logos">
              <a href="https://www.facebook.com/itsus.media/" target="_blank">
                <img src={fb}></img>
              </a>
              <a
                href="https://www.instagram.com/itsus.media/?hl=en"
                target="_blank"
              >
                <img src={insta}></img>
              </a>
              {/* <a href="https://www.twitter.com" target="_blank">
                <img src={twitter}></img>
              </a> */}
              <a
                href="https://www.linkedin.com/company/it%27s-us-media-gmbh"
                target="_blank"
              >
                <img src={linkedin}></img>
              </a>
            </div>
            <div id="side-menu-email">
              <a href="mailto:office@itsus.berlin"> office@itsus.berlin</a>
              <br />
              <a href="tel:491759490721" style={{ fontSize: '.9rem' }}>
                +49 1759 490 721
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SideMenu);

import { lazy } from "react";
import { IRoute } from "./interfaces"

//Lazy loading required pages  
const Test = lazy(()=>import('./pages/TestPage'));
const LandingPage = lazy(()=>import('./pages/LandingPage'));
const ExploreVideosPage = lazy(()=> import('./pages/ExploreVideosPage'))
const ExploreCreatorsPage = lazy(()=> import('./pages/ExploreCreatorsPage'))
const ProfilePage = lazy(()=> import('./pages/ProfilePage'))
const Contact = lazy(()=> import('./pages/Contact'))
const ImprintPage = lazy(()=> import('./pages/ImprintPage'));
const Video = lazy(() => import('./pages/Video'));
const About = lazy(() => import('./pages/About'));
/**
 * Endpoints to be used as paths
 */
export enum Endpoints {
  EXPLORE_VIDEOS = '/explore-projects',
  HOME = '/',
  EXPLORE_CREATORS = '/explore-directors',
  PROFILE_PAGE = '/profile/:creatorName',
  CONTACT = '/contact',
  IMPRINT = '/imprint',
  VIDEO = '/video/:id',
  ABOUT = '/about',
}
/**
 *  List of all page routes
 */
const routes: Array<IRoute> = [
  {
    path: Endpoints.HOME,
    // element:LandingPage
    element: LandingPage,
  },
  {
    path: Endpoints.EXPLORE_VIDEOS,
    element: ExploreVideosPage,
  },
  {
    path: Endpoints.EXPLORE_CREATORS,
    element: ExploreCreatorsPage,
  },
  {
    path: Endpoints.PROFILE_PAGE,
    element: ProfilePage,
  },
  {
    path: Endpoints.CONTACT,
    element: Contact,
  },
  {
    path: Endpoints.IMPRINT,
    element: ImprintPage,
  },
  {
    path: Endpoints.VIDEO,
    element: Video,
  },
  {
    path: Endpoints.ABOUT,
    element: About,
  },
];

export default routes;
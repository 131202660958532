import React, { useEffect, useState } from 'react';
import styles from './pageTitle.module.scss';
import SideMenu from '../../molecules/SideMenu/SideMenu';
import back from '../../../img/back.png';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import hamburger from '../../../img/svg/hamburger.svg';
interface IPageTitle {
  title: string;
  back?: boolean;
}

const PageTitle = (props: IPageTitle) => {
  const navigate = useNavigate();
  const {setDirectorsClicked} = useContext(AppContext);

  const [openNav, setOpenNav] = useState(false);

  return (
    <div className={styles.pageTitle}>
      <div className={styles.wrapper}>
        {props.back &&( <img src={back} alt="back" className={styles.back} onClick={()=>{navigate('/');setDirectorsClicked(true);}} /> )//navigate("/explore-directors");
        }
        <h1>{props.title}</h1>
        <img
          src={hamburger}
          onClick={() => {
            setOpenNav(!openNav);
          }}
        />
      </div>
      {openNav && (
        <SideMenu
          active="asdsa"
          closeNav={() => {
            setOpenNav(false);
          }}
          isOpen={openNav}
        />
      )}
    </div>
  );
};

export default PageTitle;

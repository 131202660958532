import styles from './title.module.scss';

interface TitleProps {
  title: string;
  theme: string;
}

const Title = (props: TitleProps) => {
  return (
    <div className={styles.titleContainer}>
      <h1
        className={styles.title}
        style={{ color: props.theme === 'light' ? 'white' : 'black' }}
      >
        {props.title}
      </h1>
      {/* <hr className={styles.hr}/> */}
    </div>
  );
};

export default Title;
import utils from "./utils";

// Printing the current environment name
console.log(`Running in ${process.env.REACT_APP_ENV}`);

// Checking the env object in dev mode
if (process.env.REACT_APP_ENV === "dev")
console.log(`Env object: `,process.env);

// Populating config object
const env = {
    port:process.env.REACT_APP_PORT||3000,
    ip:process.env.REACT_APP_IP||'127.0.0.1',
    protocol:process.env.REACT_APP_PROTOCOL||'http',
    isMobile:utils.isMobile(),
    BACKEND_API: process.env.REACT_APP_BACKEND_API || 'http://admin.itsus.berlin',
}

// If production env, console should be clean
if (process.env.REACT_APP_ENV === "prod") {
   console.warn = () => { }
   console.error = () => { }
}

// Creating
let config = {
   env
}
export default config




import { isVisible } from '@testing-library/user-event/dist/utils';
import React, { useEffect, useRef } from 'react'
import './LoadingScreen.scss'
import brand from '../../../img/svg/brand.svg'

export interface ILoadingScreenProps {
  children?:React.ReactNode;
  isVisible:boolean;
}

function LoadingScreen(props:ILoadingScreenProps) {
  return (
    <>
    {
    props.isVisible?
    <div className="loading-screen"
    >
        {
          props.children || 
          <div style={{
            height:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <img src={brand} />
        </div>
        }
    </div>:null
      }
      </>
  )
}

export default LoadingScreen
import { useRef, useState, useEffect, useContext } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import './VideoPlayer.scss';
import useFullscreen from '../../../hooks/useFullscreen';
import {
  BsFillPauseFill,
  BsFillPlayFill,
  BsFullscreen,
  BsFullscreenExit,
  BsFillVolumeUpFill,
  BsFillVolumeMuteFill,
} from 'react-icons/bs';
import utils from '../../../utils';
import { AppContext } from '../../../context/AppContext';
export interface IVideoPlayerProps {
  videoID: string | number;
  autoplay?: boolean;
}

function VideoPlayer(props: IVideoPlayerProps) {
  const { firstLoad, volume, setVolume } = useContext(AppContext);
  const [onControlBoard, setOnControlBoard] = useState(false);
  /**
   * Reference to the timeline element of the control-board
   */
  const timelineRef = useRef<any>(null);

  /**
   * Reference to the whole video player wrapper
   */
  const videoPlayerRef = useRef<any>(null);

  //test
  const playerRef = useRef<any>(null);
  //test

  /**
   * Customized third party hook which enables the player to provide the fullscreen functionality
   */
  const { enterFullscreen, exitFullscreen, fullscreenActive } = useFullscreen({
    fullscreenRef: videoPlayerRef,
  });

  /**
   * The player object which represents the whole vimeo player an provides with APIs to interact with the player
   */
  const [player, setPlayer] = useState<any>(null);

  /**
   * State of the video in play or pause
   */
  const [pause, setPause] = useState(false);
  /**
   * State of videos volumne to toggle from mute to unmute
   */

  // const [volume, setVolume] = useState(mute ? 0 : 1);

  /**
   * This timeline object contains the total duration of the video, fraction of video completed, and seconds of video played till now
   */
  const [timeline, setTimeline] = useState({
    duration: 0,
    percent: 0,
    seconds: 0,
  });

  /**
   * This state tells if at the current moment the seek of timeline is being dragged or not
   */
  const [isSeekInUse, setIsSeekInUse] = useState(false);

  /**
   * This value is taken from the percent property of the timeline state, which is used by timeline to know where to place the seek button
   */
  const [played, setPlayed] = useState(0);

  /**
   * This state works as a mutex to inform if in the current moment the cursor is hovering over the timeline
   */
  const [cursorInsideTimeline, setCursorInsideTimeline] = useState(false);

  /**
   * This state keeps track of the width of the player which eventually is used to calculate where to keep the seek w.r.t. the cursor,
   * if the user is trying to move the seek
   */
  const [timelineWidth, setTimelineWidth] = useState(0);

  /**
   * This state keeps track of Control Board Visibility in boolean form
   */
  const [showControlBoard, setShowControlBoard] = useState(true);

  //--------------------------------------------------------------------

  //----------------- HANDLERS -----------------------------------------

  //--------------------------------------------------------------------

  /**
   * This handler toggles the pause state variable when user clicks on Play/Pause button on the UI
   */
  const handlePlay = () => {
    setPause(!pause);
  };
  /**
   * This handler toggle from mute to unmute
   */
  const handleMute = () => {
    setVolume((prev: number) => (prev === 1 ? 0 : 1));
    // if (mute) {
    //   setVolume(0);
    // }
    // if (!mute) {
    //   setVolume(1);
    // }
    // if (volume == 0) setVolume(1);
    // if (volume === 1) setVolume(0);
  };
  /**
   * Whenever time is updated during a video play, this handler is called which updates the timeline state object
   */
  const handleTimeUpdate = (e: any) => {
    setTimeline(e);
  };

  /**
   * This handler toggles the isSeekInUse state to true depending on if the seek button on the timeline is being used
   */
  const handleSeekMouseDown = () => {
    setIsSeekInUse(true);
    setPause(true);
  };

  /**
   * Does the same thing as 'handleSeekMouseDown' but for touchable devices
   */
  const handleTouchStart = () => {
    setCursorInsideTimeline(true);
    handleSeekMouseDown();
  };

  /**
   * This handler toggles the isSeekInUse state to false depending on if the seek button on the timeline is not being used
   */
  const handleSeekMouseUp = () => {
    setIsSeekInUse(false);
    player.setCurrentTime(played * timeline.duration);
    setPause(false);
  };

  /**
   * Does the same thing as 'handleSeekMouseUp' but for touchable devices
   */
  const handleTouchEnd = () => {
    setCursorInsideTimeline(false);
    handleSeekMouseUp();
  };

  /**
   * When the cursor is moved while holding the seek button, the position of the seek is calculated and set continuously,
   * the formula for fullscreen mode is different from non fullscreen mode.
   */
  const handleMouseMove = (e: any) => {
    if (isSeekInUse && cursorInsideTimeline) {
      let percent = 0;
      if (fullscreenActive) {
        percent = (e.pageX - timelineRef.current.offsetLeft) / timelineWidth;
      } else
        percent =
          (e.pageX -
            (videoPlayerRef.current.offsetLeft -
              videoPlayerRef.current.offsetWidth / 2) -
            timelineRef.current.offsetLeft) /
          timelineWidth;
      setPlayed(percent);
    }
    e.stopPropagation();
  };

  /**
   * This function does the same thing as 'handleMouseMove' function, but for touchable devices,
   * since there is no cursor in touchable devices, seperate function was required for the same functionality
   */
  const handleTouchMove = (e: any) => {
    if (isSeekInUse) {
      let percent = 0;
      if (fullscreenActive) {
        /**
         * If fullscreen on android and orientation is portrait then we rotate the player manually using inline css and
         * below code calculates the percent variable according to the orientation
         */
        if (window.matchMedia('(orientation: portrait)').matches) {
          percent =
            (e.touches[0].pageY - timelineRef.current.offsetLeft) /
            timelineWidth;
        } else {
          percent =
            (e.touches[0].pageX - timelineRef.current.offsetLeft) /
            timelineWidth;
        }
      } else {
        percent =
          (e.touches[0].pageX -
            (videoPlayerRef.current.offsetLeft -
              videoPlayerRef.current.offsetWidth / 2) -
            timelineRef.current.offsetLeft) /
          timelineWidth;
      }
      setPlayed(percent);
    }
    e.stopPropagation();
  };

  /**
   * Whenever the position of seek changes this function runs, and if the seek is not being held by mouse
   * or finger the position is updated
   */
  const handleSeekChange = () => {
    if (!isSeekInUse) setPlayed(timeline.percent);
  };

  /**
   * This function runs when the player is loaded, the player object exposes all apis to interact with the
   * player, in this function the player object is being saved in a state variable named 'player'
   * @param player
   */
  const handleInitialise = (player: any) => {
    setPlayer(player);
  };

  /**
   * This handler simply toggles the player to and from fullscreen mode
   */
  const handleFullscreenToggle = () => {
    if (fullscreenActive) {
      exitFullscreen();
      //   console.log("exit", playerRef?.current);
      // playerRef?.current.container.classList.remove('container-2');
    } else {
      enterFullscreen();
      // const aloo = new ScreenOrientation().lock("landscape");
      // playerRef?.current.container.style['transform'] = 'rotate(90deg)';
    }
  };

  /**
   * When user clicks on the timeline in web view, the seek moves to that location via this function
   */
  const handleTimelineClick = (e: any) => {
    if (cursorInsideTimeline) {
      let percent = 0;
      if (fullscreenActive) {
        percent = (e.pageX - timelineRef.current.offsetLeft) / timelineWidth;
      } else
        percent =
          (e.pageX -
            (videoPlayerRef.current.offsetLeft -
              videoPlayerRef.current.offsetWidth / 2) -
            timelineRef.current.offsetLeft) /
          timelineWidth;
      player.setCurrentTime(percent * timeline.duration);
    }
    e.stopPropagation();
  };

  //--------------------------------------------------------------------

  //----------------- EFFECTS -----------------------------------------

  //--------------------------------------------------------------------
  /**
   * Checking whether we are visiting the video directly(deeplike) if yes set as mute other wise unmute for rest of the site videos
   */
  // useEffect(() => {
  //   if (firstLoad) setVolume(1);
  //   if (!firstLoad) setVolume(0);
  // }, []);
  /**
   * Update the percent property of the timeline state variable whenever the seek is used or the timeline.percent is changed
   */
  useEffect(() => {
    if (!isSeekInUse) setPlayed(timeline.percent);
  }, [timeline.percent]);

  /**
   * If the cursor is on the timeline then get the width of the timeline
   */
  useEffect(() => {
    if (cursorInsideTimeline) setTimelineWidth(timelineRef.current.clientWidth);
  }, [cursorInsideTimeline]);

  useEffect(() => {
    // Create a setTimeout with a 3-second delay to hide the control board
    if (!onControlBoard) {
      const timeout: any = setTimeout(() => {
        setShowControlBoard(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }

    // Cleanup function
  }, [showControlBoard, onControlBoard]);

  //--------------------------------------------------------------------

  //----------------- RETURN -----------------------------------------

  //--------------------------------------------------------------------
  return (
    <>
      {!utils.isIOS() ? (
        <div
          ref={videoPlayerRef}
          className="video-player-wrapper"
          style={
            fullscreenActive &&
            window.matchMedia('(orientation: portrait)').matches
              ? {
                  ...rotate90deg,
                }
              : {}
          }
        >
          <div
            className="control-board"
            onMouseEnter={(e) => {
              setOnControlBoard(true);
              setShowControlBoard(true);
            }}
            onMouseLeave={(e) => {
              setOnControlBoard(false);
              setShowControlBoard(false);
            }}
            // style={{display:showControlBoard?'':'none'}}
            style={
              fullscreenActive &&
              window.matchMedia('(orientation: portrait)').matches
                ? {
                    position: 'relative',
                    ...rotate90deg,
                    width: '100vh',
                    // right: "96%",
                    left: '-45vh',
                    top: '45%',
                  }
                : {}
            }
          >
            <div
              onClick={handlePlay}
              id="btn-play"
              style={{
                display: showControlBoard ? '' : 'none',
                cursor: 'pointer',
              }}
            >
              {pause ? (
                <BsFillPlayFill color={'white'} size={24} />
              ) : (
                <BsFillPauseFill color={'white'} size={24} />
              )}
            </div>
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              onMouseMove={handleMouseMove}
              onMouseEnter={(e) => {
                setCursorInsideTimeline(true);
              }}
              onMouseLeave={(e) => {
                setCursorInsideTimeline(false);
              }}
              onClick={handleTimelineClick}
              // Mobile
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onTouchMove={handleTouchMove}
              id="timeline"
              ref={timelineRef}
              style={{
                display: showControlBoard ? '' : 'none',
              }}
            />
            <div
              onClick={handleMute}
              id="btn-mute"
              style={{
                display: showControlBoard ? '' : 'none',
              }}
            >
              {volume === 1 ? (
                <BsFillVolumeUpFill color={'white'} size={24} />
              ) : (
                <BsFillVolumeMuteFill color={'white'} size={24} />
              )}
            </div>
            <div
              id="btn-fullscreen"
              style={{
                display: showControlBoard ? '' : 'none',
              }}
            >
              {fullscreenActive ? (
                <BsFullscreenExit
                  color={'white'}
                  size={20}
                  onClick={handleFullscreenToggle}
                />
              ) : (
                <BsFullscreen
                  color={'white'}
                  size={20}
                  onClick={handleFullscreenToggle}
                />
              )}
            </div>
          </div>
          <Vimeo
            ref={playerRef}
            playsInline
            onReady={handleInitialise}
            onLoaded={() => {
              if (pause) player.pause();
              else player.play();
            }}
            volume={volume}
            controls={false}
            responsive={true}
            className="player"
            video={props.videoID}
            paused={pause}
            onTimeUpdate={handleTimeUpdate}
            // autoplay={true}
            showPortrait={true}
            onPlay={(e) => {
              if (pause) {
                player.pause();
              }
              // setVolume(1);
            }}
            onPause={(e) => {
              if (!pause) {
                player.play();
              }
            }}
            // playsInline={false}
            style={
              fullscreenActive &&
              window.matchMedia('(orientation: portrait)').matches
                ? {
                    ...rotate90deg,
                    right: '50%',
                    // top:'25%',
                    width: '100vh',
                  }
                : {}
            }
          />
          <div
            className="m-interactor"
            onClick={(e) => {
              setShowControlBoard(!showControlBoard);
            }}
          />
        </div>
      ) : (
        <div
          ref={videoPlayerRef}
          className="video-player-wrapper"
          style={
            fullscreenActive &&
            window.matchMedia('(orientation: portrait)').matches
              ? {
                  ...rotate90deg,
                  // width:'100vh'
                }
              : {}
          }
        >
          <Vimeo
            // muted={muted}
            video={props.videoID}
            controls={false}
            autoplay={true}
            autopause={true}
            playsInline={false}
            onReady={handleInitialise}
            onLoaded={() => {
              player.play();
            }}
            className="ios-player"
          />
          <div className="ios-control-board">
            <BsFillPlayFill
              id="ios-play"
              color={'white'}
              size={64}
              onClick={() => {
                player.play();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default VideoPlayer;

// Style to rotate player manually

const rotate90deg = {
  transform: 'rotate(90deg)',
  msTransform: 'rotate(90deg)',
  MozTransformOrigin: 'rotate(90deg)',
  WebkitTransform: 'rotate(90deg)',
};

import Carousel from 'nuka-carousel';
import { FC, useContext, useEffect, useRef } from 'react';
import styles from './slider.module.scss';
import {AppContext} from '../../../context/AppContext';
import config from '../../../config';
import { IAttributes } from '../../../interfaces';
// import { ICreator, IExploreCreator } from '../../../interfaces';

// Slider -> Slider Container -> Explore Creator
interface IProps {
  media: IAttributes;
}
const Slider: FC<IProps> = ({ media }) => {
  // Accessing toggle value from appcontext for conditional rendering
  const { toggle } = useContext(AppContext);

  const imgContainerRef = useRef<any>(null);
  const { carouselWidth, setCarouselWidth } = useContext(AppContext);

  useEffect(() => {
    /**
     * On initial render, set carouselWidth
     */
    setCarouselWidth(imgContainerRef.current?.clientWidth);
    
    /**
     * This callback function is fired whenever the window resizes,
     * it sets the imgContainerRef's width in the AppContext
     */
    const carouselWidthHandler = () => {
      imgContainerRef.current &&
        setCarouselWidth(imgContainerRef.current.clientWidth);
    };
    //window.addEventListener("resize", carouselWidthHandler);

    return () => {
      //window.removeEventListener("resize", carouselWidthHandler);
    };
  }, []);

  useEffect(() => {
    //const root = document.documentElement;
    //root?.style.setProperty("--std-length-di", carouselWidth);
  }, [carouselWidth]);

  return (
    <div className={styles.carousel}>
      <Carousel
        wrapAround={true}
        renderCenterLeftControls={({ previousSlide }) => (
          <button onClick={previousSlide} className={styles.leftButton}>
            <i className="fa fa-angle-left arrow"></i>
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide} className={styles.rightButton}>
            <i className="fa fa-angle-right arrow"></i>
          </button>
        )}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: 'white',
            padding: '10px',
          },
        }}
      >
        {/* 
    1. Redndering slider based on the toggle value 
    2. If the value of toggle is "director" it will fetch thumbnail of movies from "Directors" api
    3. If the value of toggle is "photographer" it will fetch image of photos from "Photographer" api 
    */}
        {media?.videos?.data.map((thumb: { id: number }, index: number) => {
          return (
            <div
              className={styles.imgContainer}
              key={thumb.id}
              ref={imgContainerRef}
            >
              <img
                src={`${config.env.BACKEND_API}${media.videos.data[index].attributes.thumbnail.data.attributes.url}`}
                className={styles.img}
              />
            </div>
          );
        })}
        {/* 
          CODE : PASTE THE BELOW CODE HERE FROM LINE NO: 67  - REASON: LINE NO: 64
        */}
      </Carousel>
    </div>
  );
};

export default Slider;

/**
 * CODE FOR CONDITIONAL "DIRECTORS" AND "PHOTOGRAPHERS" DATA
 */

// {
//   toggle === 'director'
//     ? //fetching data from directors api data: thumbnail
//       media?.videos?.data.map((thumb: { id: number }, index: number) => {
//         return (
//           <div className={styles.imgContainer} key={thumb.id}>
//             <img
//               src={`${config.env.BACKEND_API}${media.videos.data[index].attributes.thumbnail.data.attributes.formats.small.url}`}
//               className={styles.img}
//             />
//           </div>
//         );
//       })
//     : // fetching data from photographers api data: photos
//       media?.photos?.data.map(
//         (image: { attributes: any; id: number }, index: number) => {
//           return (
//             <div className={styles.imgContainer} key={image.id}>
//               <img
//                 src={`${config.env.BACKEND_API}${image?.attributes?.image?.data.attributes.url}`}
//                 className={styles.img}
//               />
//               {/* <p className={styles.title}>{media.name}</p> */}
//             </div>
//           );
//         }
//       );
// }
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { isMobileOnly, isMobile } from 'react-device-detect';

interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContext = createContext<any>(null);

const AppContextProvider = (props: AppContextProviderProps) => {
  const [creators, setCreators] = useState([]);
  // const [directors, setDirectors] = useState(null);
  // const [photographers, setPhotographers] = useState(null);
  const [toggle, setToggle] = useState('director');
  const [userDetails, setUserDetails] = useState();
  const [clients, setClients] = useState();
  const [videoStory, setVideoStory] = useState();
  const [webVideoStory, setWebVideoStory] = useState();
  const [firstLoad, setFirstLoad] = useState(false);
  const [prevRoute, setPrevRoute] = useState('');
  const [playVideoId, setPlayVideoId] = useState('');
  const [more, setMore] = useState(false);
  const [activeVideoKey, setActiveVideoKey] = useState(-1);
  const [onLinkClick, setOnLinkClick] = useState<any>(null);
  const [directorsClicked, setDirectorsClicked] = useState(false);
  const [volume, setVolume] = useState<number>(0);
  const [sliderVol, setSliderVol] = useState<number>(0);
  const [directorPath, setDirectorPath] = useState<string>('');
  /**
   * This state keeps track of the carousel width, which is used to keep
   * the carousel thumbnails in 16:9 aspect ratio
   */
  const [carouselWidth, setCarouselWidth] = useState(400);
  const translate = {
    "one":"four",
    "two":"five",
    "three":"six"
  }
  const data_types = ["title","subtitle","url","icon"]

  // Fetching Video Story Data (Tiktok)
  useEffect(() => {
    const getVideoStories = async () => {
      const { data } = await axios.get(
        `${config.env.BACKEND_API}/api/stories?populate=*`
      );
      //console.log(data);
      let obj = data.data[0].attributes;
      let extend = data.data[0].attributes;
      if( data.data.length > 1) {
        extend = data.data[1].attributes;
      }
      for (const [from_key, to_key] of Object.entries(translate)) {
        console.log(`${from_key}: ${to_key}`);
        const from = "story_"+from_key+"_";
        const to = "story_"+to_key+"_";
        for (const key of data_types) {
          obj[to+key] = extend[from+key];
        }
      } 
      setVideoStory(obj);
    };
    const getWebVideoStories = async () => {
      const { data } = await axios.get(
        `${config.env.BACKEND_API}/api/story-webs?populate=*`
      );
      setWebVideoStory(data.data[0].attributes);
    };
    if (isMobile) {
      getVideoStories();
    } else {
      getWebVideoStories();
    }
  }, []);

  // Fetching "Directors" and "Photographers" data from backend (explore-creators)
  useEffect(() => {
    const getDirectors = async () => {
      const { data } = await axios.get(
        `${config.env.BACKEND_API}/api/directors?populate=videos.thumbnail`
      );
      setCreators(data.data.sort((a,b)=>{return a.attributes.position-b.attributes.position}));
    };
    getDirectors();
  }, []);
  //  const getPhotographers = async () => {
  //     const { data } = await axios.get(
  //       `${config.env.BACKEND_API}/api/photographers?populate=photos.image`
  //     );
  //     setPhotographers(data.data);
  //   };
  //   getPhotographers();
  // },

  return (
    <AppContext.Provider
      value={{
        creators,
        // directors,
        // photographers,
        setCreators,
        toggle,
        setToggle,
        userDetails,
        setUserDetails,
        clients,
        setClients,
        videoStory,
        playVideoId,
        setPlayVideoId,
        firstLoad,
        setFirstLoad,
        webVideoStory,
        carouselWidth,
        setCarouselWidth,
        prevRoute,
        setPrevRoute,
        more,
        setMore,
        activeVideoKey,
        setActiveVideoKey,
        onLinkClick,
        setOnLinkClick,
        directorsClicked,
        setDirectorsClicked,
        volume,
        setVolume,
        directorPath,
        setDirectorPath,
        sliderVol,
        setSliderVol,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};;

export default AppContextProvider;

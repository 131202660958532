import { AnyAction } from "redux";
import { ActionEnums } from "./action-enums";
import { ActionType } from "./action-types";

export interface IStoreType{
    test:number;
}

const initialStore:IStoreType = {
    test:0
}

/**
 * reducer
 * ---
 * This is a single **reducer** function for all store related interactions
 * 
 * @category State Management
 */
function reducer(state:IStoreType=initialStore,action:AnyAction):IStoreType{
   
    switch(action.type){
        case ActionEnums.TEST:
             return({
                 ...state,
                 test:state.test+parseInt(action.payload.value)
             })
        default:
            return state
        }
}


export const reducers = {
    reducer
};

// export type State = ReturnType<typeof authReducers>
import React, { Suspense, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import 'font-awesome/css/font-awesome.min.css';
import CookieConsent from "react-cookie-consent";

// React-Slick Carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routes from './routes';

import config from './config';
import LoadingScreen from './components/atoms/LoadingScreen/LoadingScreen';
import { AppContext } from './context/AppContext';
import { Backdrop } from './components/atoms';
import VideoPlayer from './components/molecules/VideoPlayer/VideoPlayer';

function App() {
  const { playVideoId, setPlayVideoId, prevRoute, firstLoad } =
    useContext(AppContext);
  let navigate = useNavigate();

  return (
    <div className="App">
      <Suspense fallback={<LoadingScreen isVisible={true} />}>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.element />}
              />
            );
          })}
        </Routes>
      </Suspense>
      <ToastContainer />
      {playVideoId && (
        <Backdrop
          isVisible={playVideoId ? true : false}
          closeBackdrop={() => {
            setPlayVideoId('');
            window.history.replaceState(null, '', `${prevRoute}`);
            if (!firstLoad) {
              navigate('/');
            }
          }}
        >
          <VideoPlayer videoID={playVideoId} />
        </Backdrop>
      )}
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Diese Website nutzt ausschlie&szlig;lich technisch notwendige Cookies. Weitere Informationen finden Sie in unserer <a href={`https://new.itsus.berlin/imprint`} target="_blank" rel="noreferrer">Datenschutzerkl&auml;rung</a>.
      </CookieConsent>
    </div>
  );
}

export default App;

import { isVisible } from '@testing-library/user-event/dist/utils';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import './Backdrop.scss';

export interface IBackdropProps {
  children: React.ReactNode;
  isVisible: boolean;
  closeBackdrop: Function;
  style?: Object;
}

function Backdrop(props: IBackdropProps) {
  const { directorPath } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <>
      {props.isVisible ? (
        <div className="backdrop" style={props?.style}>
          <span id="bd-close-bg" onClick={() => props.closeBackdrop()}></span>
          <span
            id="bd-close-btn"
            onClick={() => {
              props.closeBackdrop();
              navigate(directorPath);
            }}
          >
            &times;
          </span>
          {props.children}
        </div>
      ) : null}
    </>
  );
}

export default Backdrop
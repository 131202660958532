import styles from './button.module.scss';
import {Link} from 'react-router-dom';

interface IButton{
 title: string;
 link: string;
 clickFunc?:React.MouseEventHandler<HTMLButtonElement>;
}


const Button = (props: IButton) => {
 return(
  <div className={styles.viewMore}>
   <Link to={props.link}>
    <button onClick={props.clickFunc} className={styles.viewMoreButton}>{props.title}</button>
   </Link>
  </div>
  
 )
}

export default Button;
import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension';

import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'
import { withReduxStateSync } from 'redux-state-sync'
import {reducers} from './redux/reducers'

const allReducers = combineReducers({...reducers})

let tempStore = createStore(withReduxStateSync(allReducers),{},composeWithDevTools(applyMiddleware(thunk,createStateSyncMiddleware())))
initStateWithPrevTab(tempStore)

export const store = tempStore ;

export type StateType = ReturnType<typeof allReducers>